import React, { useEffect, useState } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import styles from "./Attend.module.css";
import { useLocation, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';


const Attend = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const ref = params.get('ref');

    const [submitted, setSubmitted] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [sending, setSending] = useState(false);

    const [menuModal, setMenuModal] = useState(false);

    const navigate = useNavigate();

    const formData = location?.state?.formData;
    const [form, setForm] = useState({
        name: formData?.firstName || formData?.name || '',
        email: formData?.email || '',
        phoneNumber: formData?.number || formData?.phoneNumber || '',
        department: formData?.department || '',
        level: formData?.level || '',
        other: '',
        referralCode: ref || '',
    });



    const idNameMapping = {
        "entry.1440485337": "name",
        "entry.884269903": "department",
        "entry.836708229": "email",
        "entry.945937685": "phoneNumber",
        "entry.1347385934": "level",
        "entry.766106145": "other",
        "entry.759610273": "referralCode",
    };


    const handleInputChange = (event) => {
        const name = idNameMapping[event.target.name];
        const value = event.target.value;

        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const [isValidated, setIsValidated] = useState(false);
    const [error, setError] = useState('');
    const validateForm = () => {
        const requiredFields = ['name', 'email', 'phoneNumber', 'department', 'level'];
        for (let field of requiredFields) {
            if (!form[field]) {
                setError(`Please fill in the ${field} field.`);
                return false;
            }
        }

        return true;
    };

    useEffect(() => {
        setIsValidated(validateForm());
    }, [form]);

    const message = `
\u{1F3C5} *I'm Going for Gold!* \u{1F3C5}

I'm excited to let you know that I'll be attending the *Going for Gold* Event. It's going to be an amazing experience and I would love for you to join me. \u{1F31F}
You can register for *FREE* at https://goingfor.gold/attend to secure your seat. \u{1F3AB}
\u{1F973} I would really love to see you there! \u{1F973}

\u{1F31F} *Let's go for gold together!* \u{1F31F}
`;

    const encodedMessage = encodeURIComponent(message);


    const checkss = () => {
        if (submitted) {
            setTimeout(function () {
                // window.location.href = "./";
            }, 3000);
            swal({
                title: "Success!",
                text: "Your seat reservation has been made, Hope to see you there! we have a lot in store for you!",
                icon: "success",
                button: {
                    text: "Share",
                    closeModal: false,
                },
            })
                .then(() => {
                    const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
                    window.open(whatsappUrl);
                });
        }
    };

    const submit = (event) => {
        event.preventDefault();
        console.log("submitting");
        setDisabled(true);
        setSubmitted(true);
        setSending(true);
        swal("Please wait...", "We are reserving your seat", "info");

        if (isValidated) {
            event.target.submit();
        }
    };

    return (
        <div className={styles.container}>
            <nav>
                <ul>
                    <li>
                        <a href="./">Home</a>
                    </li>
                    <li>
                        <a href="./#FAQ">FAQ</a>
                    </li>
                    <li>
                        <a href="./#Roadmap">Roadmap</a>
                    </li>
                    <li>
                        <a href="./#About">About</a>
                    </li>
                </ul>
            </nav>
            <iframe
                name="hidden_iframe"
                id="hidden_iframe"
                className={styles.hidden_iframe}
                onLoad={checkss}
            ></iframe>
            <h1>RSVP</h1>
            <form className={styles.AttendForm} noValidate autoComplete="off" target="hidden_iframe" onSubmit={submit} action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfMG-2frKz21MEiQ4oWPb_o7UF-T_1vYiH6zIHTLn18RfP4uA/formResponse">
                <p>
                    We are excited to have you join us for the event. Please fill in the form below to reserve your seat. We can't wait to see you!
                </p>
                <TextField id="name" name="entry.1440485337" label="Name" value={form.name} onChange={handleInputChange} fullWidth />
                <TextField id="department" name="entry.884269903" label="Department" value={form.department} onChange={handleInputChange} fullWidth />
                <TextField id="email" name="entry.836708229" label="Email" value={form.email} onChange={handleInputChange} fullWidth />
                <TextField id="phoneNumber" name="entry.945937685" label="Phone Number" value={form.phoneNumber} onChange={handleInputChange} fullWidth />
                <FormControl fullWidth>
                    <InputLabel>Level</InputLabel>
                    <Select id="level" name="entry.1347385934" value={form.level} onChange={handleInputChange}>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                        <MenuItem value={300}>300</MenuItem>
                        <MenuItem value={400}>400</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    name="entry.766106145"
                    id="other"
                    label="Do you have any additional information that you would like to share with us?"
                    value={form.sideNotes}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    fullWidth
                />
                <TextField
                    name="entry.759610273"
                    id="referralCode"
                    label="Enter Referral Code"
                    value={form.referralCode}
                    onChange={handleInputChange}
                    fullWidth
                />

                {isValidated ? <button className={styles.continueButton} disabled={disabled}>Save Me A Seat</button> : <button type="button" className={styles.continueButton} onClick={() => alert(error)}>Save Me A Seat</button>}
            </form>
        </div>
    );
};

export default Attend;