import React, { useState } from "react";
import Styles from "./Landing.module.css";

import locked from "../../Images/locked.png";
import logo from "../../Images/gfg.jpg";
import Text from "../../Images/PNText.svg";
import bird from "../../Images/gfg.jpg";
import top from "../../Images/viralTopBg.png";
import bottom from "../../Images/viralBottomBg.png";
import arrow1 from "../../Images/viralArrow1.svg";
import arrow2 from "../../Images/viralArrow2.svg";
import roadmap from "../../Images/roadmapBG.png";
import roadmapmobile from "../../Images/roadMapMobile.png";
// import Mailchimp from "../../components/Mailchimp/Mailchimp";
// import Spotlight from "../../components/Spotlight/Spotlight";
import menu from "../../Images/hamburgerMenu.png";
import Form from "../../components/Form/LandingPageForm"
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const faq = [
    {
      question: "What is Going For Gold?",
      answer:
        "Going for Gold is an upcoming award-winning inter-faculty competition featuring categories such as Quiz, Debate, Essay, Spoken Word, and Business Pitching/Start-up Battlefield. Winners stand a chance to win prizes worth N500,000, including cash prizes, medals, awards, laptops, phones, and a one-year FUTO scholarship. Consolation prizes include a power bank and an MP3 player. Each category is treated separately, and there is a winner in each category.",
    },
    {
      question: "Can I register for only one category, like only Quiz?",
      answer:
        "Yes, all categories are different, and you can register for any one, any two, any three, or all five of them. The prizes for each category are awarded separately, so you are free to register in any category you feel you are best in. The available categories are Quiz, Debate, Essay, Spoken Words, and Business Pitching/Start-up Battlefield.",
    },
    {
      question: "Who is eligible to apply?",
      answer:
        "All FUTO students of all levels are eligible to apply.",
    },
    {
      question: "I don't want to compete; Can I just attend the event?",
      answer:
        "Yes, even if you don't want to compete, you can still attend the event to witness the competition and stand a chance to win prizes for yourself in the audience.",
    },
    {
      question:
        "As a member of the audience and not a competitor, can I still win prizes?",
      answer:
        "Yes, members of the audience can answer questions that were missed by the contestants, through which they will be awarded prizes for their participation. There are also interactive sessions in between the competition where people in the audience can win prizes by participating in the fun and games entertainment session.",
    },
    {
      question:
        "When is the Going For Gold Competition taking place?",
      answer:
        "It will be taking place on the 3rd of November, 2024 at the SOPS Theatre.",
    },
    {
      question:
        "What prizes do winners stand to receive in the Going for Gold competition?",
      answer:
        `Single Category Winner (Quiz, Debate, Essay, or Spoken Words) will be awarded:<br />
    💰 Cash Prize: ₦50,000<br /> <br />
    Single Category Winner (Business Pitching/Start-up Battlefield) will be awarded:<br />
    💰 Cash Prize: ₦100,000<br /> <br />
        Double Gold Award Winner (Winner in 2 categories - Quiz, Debate, Essay, or Spoken Words) Will receive:<br />
        💰 Cumulative Cash Prize: ₦100,000<br />
        📚 One-Year Scholarship<br /> <br />
        Triple Gold Award Winner (Winner in 3 categories - Quiz, Debate, Essay, or Spoken Words) Will take home:<br />
        💰 Cumulative Cash Prize: ₦150,000<br />
        📚 One-Year Scholarship<br />
        📱 Android Phone<br /> <br />
        Quadruple Gold Award Winner (Winner in 4 categories - Quiz, Debate, Essay, or Spoken Words) Will walk away with:<br />
        💰 Cumulative Cash Prize: ₦200,000<br />
        📚 One-Year Scholarship<br />
        📱 Android Phone<br />
        💻 Laptop<br /> <br />
        Plus Consolation Prizes for other contestants`.split('<br />').map((line, i) => <React.Fragment key={i}>{line}<br /></React.Fragment>),
    }

  ];

  const [confirm, setConfirm] = useState(false);
  const [formData, setFormData] = useState({})

  const register = (formData) => {
    console.log("formData", formData);
    setFormData(formData)
    setConfirm(true);
  }

  const navigate = useNavigate();

  const [menuModal, setMenuModal] = useState(false);

  // const navigate = () => {
  //   setMenuModal(false);
  //   window.location.href = "./#EmailSubscription";
  // };

  const [popUp, setPopUp] = useState(false);
  const [show, setShow] = useState(true);

  const update = () => {
    setPopUp(true);
    localStorage.setItem('pop_status', 1);
  }

  return (
    <div className={Styles.Landing}>
      <div className={Styles.hero}>
        <nav>
          <ul>
            <li>
              <a href="./#FAQ">FAQ</a>
            </li>
            <li>
              <a href="./#Roadmap">Roadmap</a>
            </li>
            <li>
              <a href="./#About">About</a>
            </li>
          </ul>
          <div className={Styles.socialHeaderIcons}>
            <a href="https://t.me/standagainstwarchannel" target="_blank">
              <i className="fab fa-telegram" />
            </a>
            <a href="https://twitter.com/againstwar_nfts" target="_blank">
              <i className="fab fa-twitter" />
            </a>
            <a href="https://discord.gg/rRk28c49wd" target="_blank">
              <i className="fab fa-discord" />
            </a>
            <a
              href="https://www.facebook.com/Stand-Against-War-100332872629564"
              target="_blank"
            >
              <i className="fab fa-facebook" />
            </a>
            <a
              href="https://www.instagram.com/standagainst.war/"
              target="_blank"
            >
              <i className="fab fa-instagram" />
            </a>
          </div>
          <button
            onClick={() => setConfirm(true)}
          >
            Register
          </button>

          <div className={Styles.menu}>
            <img src={menu} onClick={() => setMenuModal(!menuModal)} alt="" />
          </div>
          {menuModal && (
            <div className={Styles.menuModal}>
              <div className={Styles.mobileLinks}>
                <nav>
                  <ul onClick={() => setMenuModal(false)}>
                    <li>
                      <a href="./#About">About</a>
                    </li>
                    <li>
                      <a href="./#FAQ">FAQ</a>
                    </li>
                    <li>
                      <a href="./#Roadmap">Roadmap</a>
                    </li>
                  </ul>
                  <button onClick={() => setConfirm(true)}>Register</button>
                </nav>
              </div>
            </div>
          )}
        </nav>
        <div className={Styles.text}>
          <h2>
            <b> GOING FOR GOLD v3.0: </b> Exploring Opportunities for Greatness
          </h2>
          <p>
            At Going for Gold, we believe in the power of competition to drive positive change. Join us in this exciting challenge to push your limits and support a cause that matters. Are you ready to compete, inspire, and make a lasting impact? Join us on this exhilarating journey towards gold!
          </p>

          <div className={Styles.whiteBorder}>
            <p>
              <span>Huge</span>
              <p>
                Prizes <br />
                For Winners
              </p>
            </p>
            <p>
              <span>12</span>
              <p>
                Students <br /> Registered
              </p>
            </p>
            <p>
              <span>42</span>
              <p>
                Students <br /> Attending
              </p>
            </p>
          </div>

          <button
            onClick={() => setConfirm(true)}
          >
            Register
          </button>
        </div>
        <div className={Styles.logo}>
          <img src={logo} alt="" />
        </div>
      </div>
      {/* <Mailchimp /> */}
      <div className={Styles.manifesto} id="About">
        <div className={Styles.manifestoText}>
          <h1>About</h1>
          <h5>Going for Gold: Where Excellence Meets Impact!</h5>
          <p>

            Step into the world of Going for Gold, an award-winning inter-faculty and inter-departmental competition that challenges participants in essay writing, quizzes, debates, spoken word performances, and business pitching/start-up battles.  <br /> <br />
            But it's more than just a competition; it's a platform where talent shines, ideas flourish, and voices resonate.


            Our winners don't just walk away with prizes; they stand to win cash prizes and items worth over <b>N500,000!</b> Imagine getting your hands on a brand-new laptop, the latest smartphone, scholarships, cash, power banks, earbuds, and an MP3 Bluetooth speaker—all just for participating in{" "}
            <span> Going for Gold </span> and winning!
            <blockquote>
              <i>
                {" "}
                "Winning doesn't always mean being first. Winning means you're doing better than you've ever done before.” <br />
                <span> — Bonnie Blair </span>{" "}
              </i>
            </blockquote>
            But it's not just about the prizes. It's about the cause. Going for Gold is more than a competition; it's a movement. It's about pushing boundaries, supporting each other, and creating a better world together.
          </p>
        </div>
      </div>
      <div className={Styles.viral}>
        <img src={top} alt="" />
        <div className={Styles.viralMain}>
          <div className={Styles.text}>
            <img src={Text} className={Styles.textImg} alt="" />
            <p>taking off soon!</p>
            <img src={arrow1} className={Styles.arrow1} alt="" />
            <p>Dont be late...</p>
            <h3>
              start applying now!{" "}
              <img src={arrow2} className={Styles.arrow2} alt="" />{" "}
            </h3>
          </div>
          <div className={Styles.rainbowCard}>
            <img src={bird} alt="" />
            <p>Register for GFG </p>
            <button
              onClick={() => setConfirm(true)}
            >
              Apply Now
            </button>
          </div>
        </div>
        <img src={bottom} alt="" />
      </div>
      <div id="Roadmap" className={Styles.roadmap}>
        <img src={roadmap} className={Styles.desktop} alt="" />
        <img src={roadmapmobile} className={Styles.mobile} alt="" />
        <h2>
          Roadmap <br /> Teaser
        </h2>
        <section>
          <div className={Styles.text}>
            <h2>
              Phase 1: <br />
              Pilot
            </h2>
            <p>Registrations + Onboarding + Briefings</p>
          </div>
          <span className={Styles.icon}>
            <span className={Styles.img}>

            </span>
          </span>
          <span className={Styles.line}></span>
          <span className={Styles.circle}></span>
        </section>
        <section>
          <div className={Styles.text}>
            <h2>
              Phase 2: <br />
              The Going For Gold Preliminaries
            </h2>
            <p>
              Holding on the 31st of October till 3rd November, 2024. Will Comprise of the first stage of the  competitions, winners advance to the finals
            </p>
          </div>
          <span className={Styles.icon}>
            <img src={locked} alt="" />
          </span>
          <span className={Styles.line}></span>
          <span className={Styles.circle}></span>
        </section>
        <section>
          <div className={Styles.text}>
            <h2>
              Phase 3: <br />
              The Grande Finale
            </h2>
            <p>The Final Showdown between Finalists where the Winners go home  with their prizes </p>
          </div>
          <span className={Styles.icon}>
            <img src={locked} alt="" />
          </span>
          <span className={Styles.line}></span>
          <span className={Styles.circle}></span>
        </section>
      </div>
      {/* <Spotlight /> */}
      <div id="FAQ" className={Styles.FAQ}>
        <section className={Styles.main}>
          <h1>Frequently Asked Questions</h1>
          <div className={Styles.FAQtab}>
            {faq.map(({ question, answer }, k) => (
              <details key={k}>
                <summary>{question}</summary>
                <p>{answer}</p>
              </details>
            ))}
          </div>
        </section>
      </div>
      <footer>
        <div className={Styles.container}>
          <div className={Styles.navLinks}>
            <ul>
              {/* <li>
              <Link to="./">Connect</Link>
            </li> */}
              <li>
                <a href="mailto:help@standagainstwar.com">Contact</a>
              </li>
              {/* <li>
              <Link to="./">Opensea</Link>
            </li> */}
            </ul>
          </div>
          <p>Do You have any concerns, enquiries or questions? reach out to us on whatsapp via <a href="https://wa.me/7018562336">07018562336</a> or <a href="https://wa.me/9018138586">09018138586</a> or <a href="https://wa.me/9157696979">09157696979</a></p>

          <button
            onClick={() => setConfirm(true)}
          >
            Register
          </button>
        </div>
      </footer>
      <div className={`${!popUp && Styles.closed} ${Styles.popUp} ${!show && Styles.none}`} onClick={() => { !popUp && update() }} id="GetWhitelisted">

        <Form
          onValidated={formData => register(formData)}
          setPopUp={() => setPopUp(false)}
        />
      </div>
      {
        confirm && <div className={Styles.ConfirmModal} onClick={() => setConfirm(false)} >
          <div className={Styles.ConfirmModalContent} onClick={(e) => e.stopPropagation()} >
            <h2>What would you like to register for?</h2>
            <p onClick={() => navigate("/compete", { state: { formData: formData } })}>I Want To Compete In The Competition</p>
            <p onClick={() => navigate("/attend", { state: { formData: formData } })}>I Want To Attend The Event</p>
          </div>
        </div>
      }
    </div>
  );
};

export default Landing;
