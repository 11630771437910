import React, { useEffect, useState } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import styles from "./Compete.module.css";
import { useLocation } from 'react-router-dom';
import { PaystackButton, usePaystackPayment } from 'react-paystack';
import swal from 'sweetalert';

const Compete = () => {
    const location = useLocation();
    const formData = location?.state?.formData;
    const [form, setForm] = useState({
        name: formData?.firstName || '',
        email: formData?.email || '',
        phoneNumber: formData?.number || '',
        department: '',
        faculty: '',
        level: '',
        categories: {
            essay: false,
            debate: false,
            spokenWords: false,
            quiz: false,
            businessPitching: false,
        },
    });

    const categoryFees = {
        essay: 2000,
        debate: 2000,
        spokenWords: 2000,
        quiz: 2000,
        businessPitching: 3000,
    };

    const totalAmount = Object.keys(form.categories).reduce((total, category) => {
        return form.categories[category] ? total + categoryFees[category] : total;
    }, 0);

    const publicKey = "pk_live_1b600256f2e44ab9528707b5161c68241bdd7ba4";

    const handleInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const handleCheckboxChange = (event) => {
        setForm({
            ...form,
            categories: {
                ...form.categories,
                [event.target.name]: event.target.checked,
            },
        });
    };

    const componentProps = {
        email: form.email,
        amount: totalAmount * 100,
        metadata: {
            custom_fields: [
              {
                display_name: "Name",
                variable_name: "name",
                value: form.name,
              },
              {
                display_name: "Phone",
                variable_name: "phone",
                value: form.phoneNumber,
              },
              {
                display_name: "Department",
                variable_name: "department",
                value: form.department,
              },
              {
                display_name: "Faculty",
                variable_name: "faculty",
                value: form.faculty,
              },
              {
                display_name: "Level",
                variable_name: "level",
                value: form.level,
              },
              {
                display_name: "Category",
                variable_name: "category",
                value: Object.keys(form.categories).filter((category) => form.categories[category]).join(", "),
              },
              {
                display_name: "Payment For",
                variable_name: "paymentFor",
                value: "GoingForGold Competition Registration",
              },
            ],
          },
        publicKey,
        text: "Finalize Registrations",
        onSuccess: () =>
            swal({
                title: "Your Registration has been successfully Completed!",
                text: "You are now a contestant in the competition and have completed the first step to Intellectual Domination!",
                icon: "success",
                buttons: {
                    cancel: "Close",
                    confirm: {
                        text: "Join WhatsApp Group",
                        value: "join",
                    },
                },
            })
                .then((value) => {
                    switch (value) {
                        case "join":
                            window.location.href = "https://chat.whatsapp.com/IMzk6vfUltn0yOZcAcQrVb"; 
                            break;

                        default:
                            swal.close();
                    }
                }),
        onClose: () => alert("Continue with your Registration!"),
    };

    const [isValidated, setIsValidated] = useState(false);
    const isCategorySelected = Object.values(form.categories).some(Boolean);
    const [error, setError] = useState('');
    const validateForm = () => {
        const requiredFields = ['name', 'email', 'phoneNumber', 'department', 'faculty', 'level'];
        for (let field of requiredFields) {
          if (!form[field]) {
            setError(`Please fill in the ${field} field.`);
            return false;
          }
        }
      
        if (!isCategorySelected) {
            setError('Please select at least one category.');
          return false;
        }
      
        return true;
      };

      useEffect(() => {
        setIsValidated(validateForm());
      }, [form, isCategorySelected]);

    return (
        <div className={styles.container}>
            <nav>
          <ul>
            <li>
              <a href="./">Home</a>
            </li>
            <li>
              <a href="./#FAQ">FAQ</a>
            </li>
            <li>
              <a href="./#Roadmap">Roadmap</a>
            </li>
            <li>
              <a href="./#About">About</a>
            </li>
          </ul>
        </nav>
            <h1>Competition Application</h1>
            <div className={styles.CompeteForm} noValidate autoComplete="off">
                <TextField name="name" label="Name" value={form.name} onChange={handleInputChange} fullWidth />
                <TextField name="department" label="Department" value={form.department} onChange={handleInputChange} fullWidth />
                <TextField name="email" label="Email" value={form.email} onChange={handleInputChange} fullWidth />
                <TextField name="faculty" label="Faculty" value={form.faculty} onChange={handleInputChange} fullWidth />
                <TextField name="phoneNumber" label="Phone Number" value={form.phoneNumber} onChange={handleInputChange} fullWidth />
                <FormControl fullWidth>
                    <InputLabel>Level</InputLabel>
                    <Select name="level" value={form.level} onChange={handleInputChange}>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                        <MenuItem value={300}>300</MenuItem>
                        <MenuItem value={400}>400</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                    </Select>
                </FormControl>
                <b>Select Category:</b>
                <p>You can apply for any one, two, three or all Five Categories:</p>
                <FormControlLabel control={<Checkbox name="essay" checked={form.categories.essay} onChange={handleCheckboxChange} />} label="Essay" />
                <FormControlLabel control={<Checkbox name="debate" checked={form.categories.debate} onChange={handleCheckboxChange} />} label="Debate" />
                <FormControlLabel control={<Checkbox name="spokenWords" checked={form.categories.spokenWords} onChange={handleCheckboxChange} />} label="Spoken Words" />
                <FormControlLabel control={<Checkbox name="quiz" checked={form.categories.quiz} onChange={handleCheckboxChange} />} label="Quiz" />
                <FormControlLabel control={<Checkbox name="businessPitching" checked={form.categories.businessPitching} onChange={handleCheckboxChange} />} label="Business Pitching/Start-up Battle" />
                <p>Total Registration Fee: N{totalAmount}</p>
                {!isValidated ? <button className={styles.continueButton} onClick={() => alert(error)}>Finalize Registrations</button> :
                <PaystackButton {...componentProps} className={styles.continueButton} />}
            </div>
        </div>
    );
};

export default Compete;