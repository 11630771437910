import React from "react";
import { Link } from "react-router-dom";
import Styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer>
      <div className={Styles.container}>
        <div className={Styles.navLinks}>
          <ul>
            {/* <li>
              <Link to="./">Connect</Link>
            </li> */}
            <li>
              <a href="mailto:emmanuelcharles2133@gmail.com">Contact</a>
            </li>
            {/* <li>
              <Link to="./">Opensea</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
