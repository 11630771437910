import { BrowserRouter as Router, Routes , Route } from "react-router-dom";

import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Landing from "./pages/Landing/Landing";
import Compete from "./pages/Compete/Compete";
import Attend from "./pages/Attend/Attend";

function App() {



  return (
    <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/compete" element={<Compete />} />
            <Route path="/attend" element={<Attend />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router> 
        
    </div>
  );
}

export default App;
